// extracted by mini-css-extract-plugin
export var brdrDiv = "relatedCases-module--brdrDiv--ymdhC";
export var brdrDivHide = "relatedCases-module--brdrDivHide--KWpsw";
export var cardDescription = "relatedCases-module--cardDescription--yO8oj";
export var cardImage = "relatedCases-module--cardImage--75aOZ";
export var cardImgContainer = "relatedCases-module--cardImgContainer--MjwfD";
export var cardTitle = "relatedCases-module--cardTitle--nyqO2";
export var caseCard = "relatedCases-module--caseCard--L6UKw";
export var casesContainer = "relatedCases-module--casesContainer--9krAr";
export var content = "relatedCases-module--content--2p1CH";
export var menu = "relatedCases-module--menu--ObGc8";
export var menuItem = "relatedCases-module--menuItem--7aeOk";
export var menuItemActive = "relatedCases-module--menuItemActive--LvyTb";
export var menuItemCont = "relatedCases-module--menuItemCont--H6f2G";